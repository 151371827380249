import React from 'react';
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  SelectInput,
  SimpleForm,
  TextInput,
  EditButton,
  ReferenceField,
  ReferenceInput,
  DateInput,
  BooleanInput,
  Filter,
  ExportButton,
  required,
} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import SubscriptionChangeBtn from './SubscriptionChangeBtn';

const UserIdFilter = (props) => (
  <Filter {...props}>
    <TextInput label="User Id" source="userId" />    
    <TextInput label="PhoneNumber" source="phoneNumber" alwaysOn />
  </Filter>
);

const UserSubscriptionActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    currentSort,
    total,
    exporter,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={total}
      />
    </Toolbar>
  );
};

export const UserSubscriptionList = props => (
  <List {...props} filters={<UserIdFilter/>} actions={<UserSubscriptionActions props={props} />}>
    <Datagrid optimized>
      <TextField source="id" />
      <ReferenceField label="Имайл" source="userId" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <TextField label="Утасны дугаар" source="user.phoneNumber" />
      <ReferenceField label="Subscription" source="subscriptionPlanId" reference="subscriptionPlans">
        <TextField source="type" />
      </ReferenceField>
      <TextField label="Social" source="user.social" />
      <TextField source="status" />
      <SubscriptionChangeBtn/>
      <EditButton />
    </Datagrid>
  </List>
);

export const UserSubscriptionEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput label="Хэрэглэгчийн Нэр" source="userId" reference="users">
        <SelectInput disabled optionText="firstName" />
      </ReferenceInput>
      <DateInput label="Эхлэх өдөр" source="startDate" />
      <DateInput label="Дуусах өдөр" source="endDate" />
      <BooleanInput label="Reccuring" source="isReccuring" />
      <ReferenceInput label="Subscription" source="subscriptionPlanId" reference="subscriptionPlans">
        <SelectInput optionText="type" />
      </ReferenceInput>
      <SelectInput source="status" lable="Төлөв" choices={[
        { id: 'PAID', name: 'PAID' },
        { id: 'FREE', name: 'FREE' },
        { id: 'INACTIVE', name: 'INACTIVE' },
      ]}/>
      <TextInput source="note" label="Тайлбар" multiline rows={3} fullWidth 
        validate={required()}
      />
    </SimpleForm>
  </Edit>
);

export const UserSubscriptionCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput label="Хэрэглэгчийн Нэр" source="userId" reference="users">
        <SelectInput optionText="firstName" />
      </ReferenceInput>
      <DateInput label="Эхлэх өдөр" source="startDate" />
      <DateInput label="Дуусах өдөр" source="endDate" />
      <BooleanInput label="Reccuring" source="isReccuring" />
      <ReferenceInput label="Subscription" source="subscriptionPlanId" reference="subscriptionPlans">
        <SelectInput optionText="type" />
      </ReferenceInput>
      <SelectInput source="status" lable="Төлөв" choices={[
        { id: 'PAID', name: 'PAID' },
        { id: 'FREE', name: 'FREE' },
        { id: 'INACTIVE', name: 'INACTIVE' },
      ]}/>
    </SimpleForm>
  </Create>
);
