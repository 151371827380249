import React from 'react';
import { List, Datagrid, TextField, ReferenceField, Filter, TextInput, SelectInput, useNotify, useDataProvider, Button, useRefresh, Show, SimpleForm, ShowButton } from 'react-admin';
import { UNITEL_CARRIER_BILLING_SUBSCRIPTION_STATUS } from '../../constants';
import CarrierBillingSubscriptionChange from '../CarrierBillingSubscriptionChange';

const SubscriptionFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Утасны дугаар" source="phoneNumber" alwaysOn />
    <TextInput label="User Id" source="userId" alwaysOn />
    <TextInput label="Reference Id" source="referenceId" alwaysOn />
    <SelectInput source="status" choices={UNITEL_CARRIER_BILLING_SUBSCRIPTION_STATUS} />
  </Filter>
);

const CheckButton = props => {
  const { record } = props;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const handleClick = () => {
    dataProvider.get(`carrierBillingSubscriptions/${record.id}/check`, {})
      .then((res) => {
        if (res.data.status === UNITEL_CARRIER_BILLING_SUBSCRIPTION_STATUS.ACTIVE) {
          notify('Success: Эрх идэвхтэй байна', 'success')
        } else {
          notify('Error: Эрх идэвхгүй байна', 'warning')
        }
      }
      )
      .catch((e) => {
        console.log(e)
        notify('Error:', 'warning')
      })
      .finally(() => {
        refresh();
      });
  };
  return (
    <div>
      <Button label="Эрх шалгах" onClick={handleClick} />
    </div>
  )
}

export const CarrierBillingSubscriptionList = props => (
  <List {...props} filters={<SubscriptionFilter />}>
    <Datagrid optimized>
      <TextField source="id" />
      <TextField label="Reference Id" source="referenceId" />
      <TextField label="Утасны дугаар" source="msisdn" />
      <TextField label="Package Id" source="packageId" />
      <ReferenceField source="userId" label="Хэрэглэгч" reference="users">
        <TextField label="Хэрэглэгч" source="firstName" />
      </ReferenceField>
      <TextField label="Төрөл" source="recurringPeriod" />
      <TextField label="Төлөв" source="status" />
      <CheckButton />
      <ShowButton label="Дэлгэрэнгүй" />
    </Datagrid>
  </List>
);

export const CarrierBillingSubscriptionShow = props => (
  <Show {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput disabled source="referenceId" fullWidth />
      <TextInput disabled source="msisdn" label="Phone Number" />
      <TextInput disabled source="packageId" />
      <TextInput disabled source="userId" />
      <TextInput disabled source="userSubscriptionId" />
      <TextInput disabled source="recurringPeriod" />
      <TextInput disabled source="status" />
      <CarrierBillingSubscriptionChange />
      <TextInput disabled source="expiredAt" />
    </SimpleForm>
  </Show>
);