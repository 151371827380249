import React, { useState } from 'react';
import { useNotify, useDataProvider, Button, useRefresh } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core';
import { PLAN_CHOICES } from '../constants';

const SubscriptionChangeBtn = ({ record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [planType, setPlanType] = useState(PLAN_CHOICES[0].id);
  const [coupon, setCoupon] = useState('');
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState('');

  const handleClick = () => {
    setLoading(true);
    dataProvider.patch(`userSubscriptions/${record.userId}`, {
      data: {
        planType,
        coupon,
        note
      }
    })
      .then(() => {
        setOpen(false);
        notify('Subscription updated successfully', 'success');
      })
      .catch((e) => {
        console.log(e);
        notify('Error: code not activated', 'warning');
      })
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };

  return (
    <>
      <select value={planType} onChange={(ev) => setPlanType(ev.target.value)}>
        {PLAN_CHOICES.map((plan) => (
          <option key={plan.id} value={plan.id}>{plan.name}</option>
        ))}
      </select>
      <input
        placeholder="coupon"
        value={coupon}
        maxLength={8}
        minLength={6}
        onChange={(ev) => setCoupon(ev.target.value.toUpperCase())}
      />
      <Button onClick={() => setOpen(true)} label="Идэвхжүүлэх" disabled={loading} />

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="subscription-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="subscription-dialog-title">
          Тайлбар
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            placeholder="Тайлбар"
            value={note}
            multiline
            rows={3}
            onChange={(e) => setNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} label="Цуцлах" />
          <Button onClick={handleClick} label="Идэвхжүүлэх" disabled={loading} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubscriptionChangeBtn;
